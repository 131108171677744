$( document ).ready(function() {
    console.log( "ready!" );
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="dropdown"]').dropdown()
   
    

    $(window).resize(function() {
        mainhResize();
        if ($(window).width() > 1600) {
            $('header [data-toggle="dropdown"]').attr('data-hover', 'dropdown').removeAttr('data-toggle');
        } else {
            if ($(window).width() <= 1600) { // 假设768px为移动设备的阈值
                $('.dropdown-toggle').attr('data-toggle', 'dropdown');
            }
        }
    });
    if ($(window).width() <= 1600) { // 假设768px为移动设备的阈值
        $('.dropdown-toggle').attr('data-toggle', 'dropdown');
    }

    $('.sticky-wrapper').stickynav();
    //$("header").sticky();
    let isMenuAlreadyOpen = false;
    $('.navbar-toggler').on('click',()=>{
            $('body').css("overflow",isMenuAlreadyOpen?"auto":"hidden")
            isMenuAlreadyOpen = !isMenuAlreadyOpen
    })
     //Check to see if the window is top if not then display button
     $('.scrollToTop').fadeOut();
     $(window).scroll(function(){
        if ($(this).scrollTop() > 100) {
            $('.scrollToTop').fadeIn();
        } else {
            $('.scrollToTop').fadeOut();
        }
    });
    
    //Click event to scroll to top
    $('.scrollToTop').click(function(){
        AOS.refresh();
        AOS.init({
            once: true,
            duration: 700
        })
        $('html, body').animate({scrollTop : 0},800);
        return false;
    });
    function  mainhResize(){
              console.log('resize');
              //var headerh = $("header").height();
              var headerh = 0;
              var footerh = $("footer").height();
              var windowh = $(window).height();
              var contactTitle = $(".page-header").height();
              var mainh = windowh - footerh;
              $('main').css("min-height", mainh + 'px');
              $('.full-height').css("min-height", (windowh) + 'px');
              $('.auto-height').css("min-height", (windowh - headerh ) + 'px');
              $('.kv-height').css("min-height", (windowh - headerh ) + 'px');
              //$("header").sticky();
              
           //$('.contact-content').css("min-height", (mainh) + 'px');
     } 
      $(window).resize(function()
         {
              mainhResize()
         });
     mainhResize()
     $('a[href*=\\#]').on('click',function(e) {

        var target = this.hash;
        var $target = $(target);
        console.log(targetname);
        var targetname = target.slice(1, target.length);
    
        if(document.getElementById(targetname) != null) {
             e.preventDefault();
        }
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top //or the height of your fixed navigation 
    
        }, 900, 'swing', function () {
            window.location.hash = target;
      });
    }); 
    $('.nav-item.dropdown.main-menu').hover(
        function() {
            $(this).addClass('show'); // Add class 'show' on mouse enter
            $(this).find('.dropdown-menu').addClass('show'); // Add class 'show' to dropdown-menu
        }, 
        function() {
            $(this).removeClass('show'); // Remove class 'show' on mouse leave
            $(this).find('.dropdown-menu').removeClass('show'); // Remove class 'show' from dropdown-menu
        }
    );
    AOS.init({
        //once: true,
        anchorPlacement: 'center-center',
        duration: 1500,
    })
});